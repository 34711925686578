<template>
    <span>Logging out...</span>
</template>

<script>
import Api from '../scripts/api'

export default {
    async created() {
        await Api.logout();
        window.location.replace("/");
    }
}
</script>